
body {
  background-color: #0e0f12; /* Fallback color for background */
  color: #f2f2f2; /* Light text color */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background: url('../public/BG_1.jpg') no-repeat center center fixed;
  background-size: cover;
  font-family: "Lato","BlinkMacSystemFont","Segoe UI","Helvetica Neue","Helvetica","Arial","sans-serif";
}

  
  @keyframes AnimationName {
    0% { background-position: 91% 0% }
    50% { background-position: 10% 100% }
    100% { background-position: 91% 0% }
  }
  
  .container {
    margin: 20px auto !important;
    border: 1px solid #2ecc71;
    border-radius: 15px;
    padding: 30px !important;
    background-color: #343c3d; /* Darker container background */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.8);
    max-width: 650px !important;
    width: 100% !important;
  }
  
  h1 {
    margin-bottom: 25px !important;
    color: #f2f2f2 !important; /* Crypto green color */
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .rule-counter {
    margin-bottom: 25px !important;
    color: #f2f2f2 !important; /* Crypto green color */
    text-align: left;
    /* font-size: 1rem; */
  }

  .counter{
    color: #f2f2f2 !important;
  }
  
  input.form-control {
    border-radius: 30px;
    padding: 12px 20px;
    font-size: 1rem;
    background-color: #2a2a2a;
    border: 2px solid #2ecc71;
    color: #e0e0e0;
  }
  
  input.form-control::placeholder {
    color: #aaa; /* Lighter placeholder text */
  }
  
  .btn-primary, .btn-secondary, .btn-success, .btn-add {
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    transition: background-color 0.3s, transform 0.3s ease;
  }
  
  .btn-add {
    background-color: #2ecc71 !important;
    color: #f2f2f2 !important;
  }
  
  .btn-add:hover {
    background-color: #267387;
    transform: scale(1.05); /* Slight zoom effect */
  }
  
  .btn-danger {
    background-color: #2ecc71 !important;
    border: 1px #629584 solid !important;
    color: #f2f2f2;
    transition: background-color 0.3s ease;
  }
  
  .btn-danger:hover {
    background-color: #cc0000;
  }

  .btn-edit{
    margin-right: 10px !important;
    background-color: #2ecc71 !important;
  }

  .btn-save{
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  



  /* ////////////////////// */

.pinned-separator{
  border: 1px #f2f2f2 solid;
  margin-bottom: 20px;
  
}


.pinned-btn {
  background-color: transparent !important;
  border: none !important; /* Remove border */
  outline: none !important; /* Remove outline */
  padding-right: 12px !important; /* Remove padding */
}

.pin-icon {
  color: #A02334 !important; /* Red color for pinned state */
}

.unpin-icon {
  color: gray !important; /* Gray color for unpinned state */
}

.pinned-btn:hover .pin-icon,
.pinned-btn:hover .unpin-icon {
  opacity: 0.7 !important; /* Optional: hover effect */
}


  .input-group .form-control {
    height: auto;
    padding: 12px 20px; /* Ensure consistent padding */
    font-size: 1rem;
    background-color: #2a2a2a;
    border: 2px solid #2ecc71;
    color: #f2f2f2;
    border-radius: 30px 0 0 30px; /* Rounded corners for the input */
  }
  
  .input-group .btn-add {
    height: auto;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 0 30px 30px 0; 
    color:#f2f2f2;
    border: 2px solid #2ecc71;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .input-group .btn-add:hover {
    background-color: #267387;
    transform: scale(1.05); /* Slight zoom effect */
  }
  
  .input-group .input-group-append .btn {
    margin-left: -2px; /* Avoid gap between the input and button */
  }
  
  /* ///////////////////// */
  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent !important; /* Remove white background */
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 15px;
    border: none !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6); /* Add darker shadows */
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .list-group-item:hover {
    background-color: #1f2329 ; /* Darker hover background */
    transform: translateY(-1px); /* Slight hover lift effect */
  }

  .fade-in {
    transform: translateY(-2px);
    opacity: 1;
  }
  
  .fade-out {
    transform: translateY(2px); /* Move down when checked */
    opacity: 0.5; /* Faded look for checked rules */
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  
  
  .custom-checkbox {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    border-radius: 6px; /* Give checkboxes a more modern look */
    border: 2px solid #2ecc71;
    background-color: #1c1f25;
  }
  
  .custom-checkbox:checked {
    background-color: #1abc9c; /* Green color for checked */
  }
  
  .checkbox-label {
    flex-grow: 1;
    margin-left: 10px;
    font-size: 1.2rem;
    color: #f2f2f2; /* White text for rules */
    transition: text-decoration 0.2s ease;
  }
  
  .checkbox-label.checked {
    text-decoration: line-through; /* Strikethrough effect */
    color: #888;
  }
  
  .text-center .btn {
    margin: 10px;
  }
  
  .spinner-border-sm {
    margin-right: 8px; /* Add space between spinner and text */
  }
  
  @media (max-width: 768px) {
    .container {
      max-width: 95%;
      padding: 20px;
    }
  
    h1 {
      font-size: 1.8rem;
    }
  }
  


  .logout-icon {
    background: none;
    border: none;
    cursor: pointer;
    color: #ff6b6b; /* Stylish red color */
    font-size: 1.5em;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .logout-icon:hover {
    color: #ff3b3b;
    transform: scale(1.1);
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .username-input {
    flex: 1;
    border-radius: 30px 0 0 30px;
  }
  
  .email-domain-hint {
    display: flex;
    align-items: center;
    padding-left: 8px;
    color: #f2f2f2;
    font-weight: 400;
    font-size: 1rem;
    background: none;
    border: none;
  }
  
  .input-group {
    display: flex;
    align-items: center;
  }
  .input-group-append {
    border-radius: 0 30px 30px 0;
    border-left: none;
  }
  
.dragging {
  transform: scale(1.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

/* Animation for checking/unchecking */
.checkbox-label {
  transition: color 0.3s ease, transform 0.3s ease;
}

.checked {
  color: #888;
  transform: scale(1.02);
}



.sticky-note {
  position: absolute;
  background-color: #629584;
  padding: 26px 12px 12px 12px;
  width: 150px;
  height: 150px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.delete-button {
  position: absolute;
  top: 2px;
  right: 2px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 22px;
  color: #ff0000;
}

.note-textarea {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  outline: none;
}

.fetching-text{
  color: #f2f2f2;
}